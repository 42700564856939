<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <alarmes-listar-filtros
          v-model="filtros"
          :carregando="carregando"
          @buscar="carregarAlarmes"
        />
      </v-col>
    </v-row>

    <v-row class="mt-4">
      <v-col>
        <v-sheet elevation="1">
          <v-data-table
            disable-filtering
            disable-sort
            :server-items-length="totalRegistros"
            :loading="carregando"
            :headers="cabecalho"
            :items="alarmes"
            :options.sync="paginacao"
            :item-class="corLinhaTabela"
            :footer-props="{
              'items-per-page-options': [10, 20, 50],
            }"
            @update:options="carregarAlarmes"
          >
            <template v-slot:[`item.data_registro_alerta`]="{ item }">
              {{ item.data_registro_alerta | data("DD/MM/YYYY HH:mm") }}
            </template>

            <template v-slot:[`item.data_registro_leitura`]="{ item }">
              {{ item.data_registro_leitura | data("DD/MM/YYYY HH:mm") }}
            </template>

            <template v-slot:[`item.actions`]="{ item }" class="text-right">
              <v-btn
                icon
                small
                title="Histórico"
                :to="`alarmes/${item.alerta_id}/historico`"
              >
                <v-icon small v-text="'mdi-history'" />
              </v-btn>
              <v-btn
                icon
                small
                :loading="
                  carregandoVisualizar && alarmeIdMostrar == item.alerta_id
                "
                @click="abrirAlarme(item)"
              >
                <v-icon small v-text="'mdi-eye'" />
              </v-btn>
            </template>
          </v-data-table>
        </v-sheet>
      </v-col>
    </v-row>

    <dialog-alarme
      v-if="mostrarAlarme"
      v-model="mostrarAlarme"
      :alarme-id="alarmeIdMostrar"
      @carregando="(e) => (carregandoVisualizar = e)"
    />
  </v-container>
</template>

<script>
import apiAlertas from "@/api/alertas";
import dayjs from "dayjs";

export default {
  components: {
    DialogAlarme: () => import("@/components/dialog/alarme/DialogAlarme"),
    AlarmesListarFiltros: () => import("./AlarmesListarFiltros"),
  },

  data() {
    return {
      teste: [],
      carregando: false,
      carregandoVisualizar: false,
      totalRegistros: 0,
      alarmeIdMostrar: 0,
      cabecalho: [
        { text: "#", value: "alerta_id" },
        { text: "Descrição", value: "descricao", width: "40%" },
        { text: "Placa", value: "placa" },
        { text: "Data Alerta", value: "data_registro_alerta" },
        { text: "Data Leitura", value: "data_registro_leitura" },
        { text: "Ações", value: "actions", align: "right" },
      ],
      alarmes: [],
      paginacao: {},
      mostrarAlarme: false,
      filtros: {
        tipo_data: 2,
        data_inicio: dayjs().startOf("month").format("YYYY-MM-DD"),
        data_fim: dayjs().endOf("month").format("YYYY-MM-DD"),
      },
    };
  },

  methods: {
    async carregarAlarmes() {
      try {
        this.carregando = true;

        const paginacao = {
          pagina: this.paginacao.page,
          por_pagina: this.paginacao.itemsPerPage,
        };

        const resposta = await apiAlertas.listarAlertasAssumidos({
          ...paginacao,
          ...this.filtros,
        });

        this.alarmes = resposta.data.data;
        this.totalRegistros = resposta.data.meta.total;
        this.teste.push(this.totalRegistros);
      } catch (e) { 
        this.$snackbar.mostrar({ cor: "error", mensagem: this.$erroApi._(e) });
      } finally {
        this.carregando = false;
      }
    },

    abrirAlarme(alarme) {
      this.alarmeIdMostrar = alarme.alerta_id;
      this.mostrarAlarme = true;
    },

    corLinhaTabela(item) {
      const permissaoOperador =
        this.$store.state.usuario.usuario.permissoes.filter((permissao) => {
          return permissao.name === "permissao-operador";
        });

      if (
        permissaoOperador.length > 0 &&
        parseInt(item.status_resposta) === 0
      ) {
        return "amber accent-1";
      } else if (
        permissaoOperador.length <= 0 &&
        parseInt(item.status_resposta) === 1
      ) {
        return "amber accent-1";
      }

      return "";
    },
  },
};
</script>
